import React, { Component } from "react"

import lottie from "lottie-web"
import LottieLogo from "@images/lottie/logo.json"

export default class Logo extends Component {
  constructor(props) {
    super(props)
    this.id = `lottie-${props.id}`
    this.logoAnimation = null

    this.handleMouseOver = () => {
      if (props.id === "footer") {
        this.logoAnimation.setSpeed(1)
        this.logoAnimation.play()
      }
    }

    this.handleMouseOut = () => {
      if (props.id === "footer") {
        this.logoAnimation.setSpeed(-1)
        this.logoAnimation.play()
      }
    }
  }

  componentDidMount = () => {
    const { id = null, setRef = null } = this.props
    this.logoAnimation = lottie.loadAnimation({
      autoPlay: false,
      container: document.getElementById(this.id),
      renderer: "svg",
      animationData: LottieLogo,
      rendererSettings: {
        preserveAspectRatio: "xMinYMax meet",
      },
    })
    if (setRef) setRef(this.logoAnimation)
    this.logoAnimation.goToAndStop(
      id === "navbar" ? this.logoAnimation.getDuration(true) : 0,
      true
    )
  }

  render() {
    return (
      <div
        className="lottie_logo"
        onClick={this.props.onClick}
        onKeyDown={this.props.onClick}
        role="button"
        tabIndex={0}
      >
        <div
          id={this.id}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onFocus={this.handleMouseOver}
          onBlur={this.handleMouseOut}
          role="button"
          tabIndex={0}
          aria-label="Netgen"
        />
      </div>
    )
  }
}
