import voca from "voca"

let translations = {}
let navigations = {}
let cases = []
let contacts = []

export const getTranslations = () => {
  return translations
}

export const setTranslations = _translations => {
  _translations.forEach(({ node: { key, label } = {} }) => {
    translations[key] = label
  })
}

export const getNavigations = () => {
  return navigations
}

export const setNavigations = _navigations => {
  _navigations.forEach(
    ({ node: { key, showSublinks, pages } = {} }) =>
      (navigations[voca.snakeCase(key)] = {
        key,
        showSublinks,
        ...{ pages },
      })
  )
}

export const getCases = () => {
  return cases
}

export const setCases = _cases => {
  cases.length = 0
  _cases.forEach(({ node }) => cases.push(node))
}

export const getContacts = () => {
  return contacts
}

export const setContacts = _contacts => {
  contacts.length = 0
  _contacts.forEach(({ node }) => contacts.push(node))
}
