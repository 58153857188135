import React, { useEffect } from "react"
import { graphql } from "gatsby"
import BodyClassName from "react-body-classname"

import { setTranslations, setNavigations, setContacts, setCases } from "@/data"

import ErrorBoundary from "@components/errorBoundary"
import SEO from "@components/seo"

import Cookies from "@components/cookies"
import Header from "@components/header"
import Navbar from "@components/navbar"
import Subnavbar from "@components/subnavbar"
import Section from "@components/section"
import Footer from "@components/footer"

const Page = ({ data }) => {
  useEffect(() => {
    window.location.href = "https://www.moment.dk/fagomraader/it"
  }, [])

  return null
  let {
    page,
    page: { node_locale: locale, title, header, sections } = {},
    navigations,
    translations,
    contacts,
    cases,
  } = data
  const slug = page.slug || ""
  const layout = (page.layout || "").toLowerCase()

  setTranslations(translations ? translations.edges || [] : [])
  setNavigations(navigations ? navigations.edges || [] : [])
  setContacts(contacts ? contacts.edges || [] : [])
  setCases(cases ? cases.edges || [] : [])

  const updateAnimations = () => {
    // ON APPEAR IN VIEWPORT
    // Trigger animations down on the page
    const pctOffset = 0.25
    Array.from(
      document.querySelectorAll("[data-animation]:not(.animation-started)")
    ).forEach(item => {
      const space = item.getBoundingClientRect()
      const wHeight = window.innerHeight
      if (space.top < wHeight - wHeight * pctOffset) {
        item.classList.add("animation-started")
        Array.from(item.querySelectorAll("span")).forEach((span, i) => {
          span.style.animationDelay = `${i * 100}ms`
        })
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("scroll", updateAnimations)
    setTimeout(updateAnimations, 1)

    const hashElement = document.getElementById(
      window.location.hash.replace("#", "")
    )
    if (hashElement) {
      if (window.scrollTo)
        window.scrollTo({
          top: hashElement.getBoundingClientRect().top,
          behavior: "smooth",
        })
    }
    return () => {
      window.removeEventListener("scroll", updateAnimations)
    }
  }, [])

  return (
    <ErrorBoundary>
      <BodyClassName className={`page-${layout}`} />
      <SEO title={title} />
      <Cookies />
      <Navbar {...{ slug, locale }} />
      {header && <Header {...{ ...header, layout }} />}
      <Subnavbar {...{ slug, locale, sections, layout }} />
      <main id="sections">
        {(sections || []).map((data, i) => (
          <Section {...{ data }} key={i} />
        ))}
      </main>
      <Footer />
    </ErrorBoundary>
  )
}

export const pageQuery = graphql`
  query ContentfulPage($id: String, $locale: String = "da") {
    translations: allContentfulTranslations(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          key
          label
        }
      }
    }
    page: contentfulPage(id: { eq: $id }) {
      node_locale
      title
      slug
      layout
      header {
        title
        body {
          body
        }
        links {
          ...linkFields
        }
        background {
          file {
            url
            fileName
            contentType
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      sections {
        id
        title
        pretitle
        module {
          __typename
          ... on Node {
            ... on ContentfulModuleText {
              graphicElement
              picture {
                file {
                  url
                  fileName
                }
              }
              link {
                ...linkFields
              }
              body {
                raw
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleList {
              listTitle
              listItems
              link {
                ...linkFields
              }
              body {
                raw
              }
            }
            ... on ContentfulModuleImages {
              link {
                ...linkFields
              }
              images {
                file {
                  url
                  fileName
                }
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleLead {
              link {
                ...linkFields
              }
              address
              body {
                raw
              }
            }
            ... on ContentfulModuleTeam {
              __typename
            }
            ... on ContentfulModuleMap {
              address
              body {
                raw
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleContactPerson {
              contactPersons {
                title
                fullName
                phoneNumber
                email
                photo {
                  file {
                    url
                    fileName
                  }
                }
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleIconlist {
              items {
                icon
                title
                body
              }
              body {
                raw
              }
              picture {
                file {
                  url
                  fileName
                }
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleProcess {
              items {
                title
                body
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleJobsTeaser {
              node_locale
              picture {
                file {
                  url
                  fileName
                }
              }
              link {
                ...linkFields
              }
              body {
                raw
              }
            }
          }
          ... on Node {
            ... on ContentfulModuleVideo {
              body {
                raw
              }
              youtubeVideo
            }
          }
          ... on Node {
            ... on ContentfulModuleText2XColumns {
              titleLeftSide
              titleRightSide
              bodyLeftSide {
                raw
              }
              bodyRightSide {
                raw
              }
              linkLeftSide {
                ...linkFields
              }
              linkRightSide {
                ...linkFields
              }
            }
          }
        }
      }
    }
    navigations: allContentfulNavigation(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          key
          showSublinks
          pages {
            updatedAt
            node_locale
            title
            slug
            layout
            sections {
              pretitle
            }
          }
        }
      }
    }
    contacts: allContentfulTeam(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          title
          fullName
          phoneNumber
          email
          photo {
            file {
              url
              fileName
            }
          }
        }
      }
    }
    cases: allContentfulCase(
      sort: { fields: [order], order: ASC }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          order
          title
          body {
            raw
          }
          logo {
            file {
              url
              fileName
            }
          }
          image {
            file {
              url
              fileName
            }
          }
          youtubeVideo
        }
      }
    }
  }
`

export default Page
