import React from "react"

export default ({ children, delay = 0, tag: Tag = "div", ...rest }) => (
  <Tag
    data-animation="slideUpFade"
    style={{ animationDelay: `${delay}ms` }}
    {...rest}
  >
    {children}
  </Tag>
)
