import React, { Component } from "react"

export default class IconList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
    }

    this.handleClick = i => {
      this.setState({ expanded: i === this.state.expanded ? false : i })
    }
  }

  render() {
    const { items, size = null } = this.props
    return (
      <div className={`iconlist${size ? ` iconlist-${size}` : ""}`}>
        {(items || []).map(({ icon, title, body }, i) => (
          <div
            className={`iconlist__item${
              this.state.expanded === i ? " iconlist__item-expanded" : ""
            }${body ? ` iconlist__item-expandable` : ""}`}
            key={i}
            onClick={() => {
              if (body) this.handleClick(i)
            }}
            onKeyDown={() => {
              if (body) this.handleClick(i)
            }}
            role="button"
            tabIndex={0}
          >
            <div className="iconlist__icon">
              <i className={`icon-${icon} ${size ? `icon-${size}` : ""}`}></i>
            </div>
            <div
              className="iconlist__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {body && <div className="iconlist__body">{body}</div>}
          </div>
        ))}
      </div>
    )
  }
}
