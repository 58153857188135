import React from "react"

import { getContainedWordsAsDangerousHTML } from "@/utils.js"

export default ({ title, tag: Tag = "h2", ...rest }) => (
  <Tag
    data-animation="wordByWord"
    {...{ ...getContainedWordsAsDangerousHTML(title), ...rest }}
  />
)
