import React, { Component } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { getCases } from "@/data";
import { numberToTens, getYoutubeID } from "@/utils";

import FallbackImage from "@images/case_image_fallback.svg";
import BlipHeading from "@components/blipHeading";

export default class CasesComponent extends Component {
  constructor(props) {
    super(props);

    this.cases = getCases();
    this.numbersRef = React.createRef();

    this.state = {
      currentCase: 0,
      pastCase: 0
    };

    this.animatePastNumber = current => {
      this.setState({ pastCase: current });
      const elem = this.numbersRef.current;
      elem.classList.remove("playing");
      setTimeout(() => {
        elem.classList.add("playing");
      }, 50);
    };

    this.next = () => {
      const { currentCase } = this.state;
      this.animatePastNumber(currentCase);
      this.setState({
        currentCase: currentCase < this.cases.length - 1 ? currentCase + 1 : 0
      });
    };

    this.prev = () => {
      const { currentCase } = this.state;
      this.animatePastNumber(currentCase);
      this.setState({
        currentCase: currentCase > 0 ? currentCase - 1 : this.cases.length - 1
      });
    };
  }

  render() {
    const { pretitle } = this.props;
    const { currentCase, pastCase } = this.state;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="row align-items-end">
            <div className="col flex-grow-0">
              <BlipHeading
                tag="span"
                title={pretitle}
                className="title d-inline"
              />
            </div>
            <div className="cases__navbar col flex-grow-1">
              <span className="cases__numbers playing" ref={this.numbersRef}>
                <span className="cases__numbers__past">
                  {numberToTens(pastCase + 1)}
                </span>
                <span className="cases__numbers__cur">
                  {numberToTens(currentCase + 1)}
                </span>
                <span className="invisible">
                  {numberToTens(currentCase + 1)}
                </span>
              </span>
              <span className="cases__numbers cases__numbers-muted">
                /{numberToTens(this.cases.length)}
              </span>
            </div>
            <div className="col flex-grow-0 pr-0">
              <button
                className="btn btn-arrow btn-arrow-left mr-3"
                onClick={() => this.prev()}
                aria-label="Previous"
              />
            </div>
            <div className="col flex-grow-0 pl-0">
              <button
                className="btn btn-arrow"
                onClick={() => this.next()}
                aria-label="Next"
              />
            </div>
          </div>
          <hr className="mt-3 mb-5 mb-md-7" />
          <div className="cases">
            {this.cases.map(
              ({ logo = {}, body = null, youtubeVideo, image }, i) => {
                const { file: { url: logoUrl, fileName: logoFileName } = {} } =
                  logo || {};
                const {
                  file: {
                    url: imageUrl = null,
                    fileName: imageFileName = ""
                  } = {}
                } = image || {};

                return (
                  <div
                    className={`row cases__item${
                      i === currentCase
                        ? "cases__item-selected animation-started"
                        : ""
                    }`}
                    key={i}
                  >
                    <div className="col-md-5">
                      <img
                        src={logoUrl}
                        alt={logoFileName}
                        className="cases__logo mb-3 mt-md-0"
                      />
                      <div className="cases__description text-break">
                        {renderRichText(body)}
                      </div>
                    </div>
                    <div className="col-md-6 offset-md-1">
                      {(youtubeVideo && (
                        <div className="colored_frame colored_frame-right colored_frame-no_delay colored_frame-dark w-100 mt-4 mt-md-0">
                          <div className="embed-responsive embed-responsive-4by3">
                            <iframe
                              width="100%"
                              height="100%"
                              src={`https://www.youtube.com/embed/${getYoutubeID(
                                youtubeVideo
                              )}`}
                              frameBorder="0"
                              title={logoFileName}
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          </div>
                        </div>
                      )) ||
                        (imageUrl && (
                          <div className="colored_frame colored_frame-right colored_frame-no_delay colored_frame-dark mt-4 mt-md-0">
                            <img
                              src={`${imageUrl}?fit=thumb&w=526&h=390`}
                              alt={imageFileName}
                              className="w-100"
                            />
                          </div>
                        )) || (
                          <img
                            src={FallbackImage}
                            alt=""
                            className="w-100 d-none d-md-block mt-4 mt-md-0"
                          />
                        )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  }
}
