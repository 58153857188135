import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import da from "javascript-time-ago/locale/da"
import cssVariables from "@/styles/_variables.custom.scss"

TimeAgo.addLocale(en)
TimeAgo.addLocale(da)

export const getContainedWordsAsDangerousHTML = text => {
  const words = (text || "").split(" ")
  return {
    dangerouslySetInnerHTML: {
      __html: `<span>${words.join("</span> <span>")}</span>`,
    },
  }
}

export const numberToTens = num => (String(num).length < 2 ? `0${num}` : num)

export const getLocaleURL = (slug, locale = "da") => {
  const baseUrl = locale === "da" ? "/" : `/${locale}/`
  return `${baseUrl}${slug ? slug : ""}`
}

export const getTimeAgo = (date, locale = "da") => {
  const timeAgo = new TimeAgo(locale)
  return timeAgo.format(new Date(date))
}
export const getYoutubeID = url => {
  let ID = ""
  url = (url || "")
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i)
    ID = ID[0]
  } else {
    ID = url
  }
  return ID
}

export const getColorByLayout = layout => {
  switch (layout.toLowerCase()) {
    case "red":
      return cssVariables.colorsRed
    case "blue":
      return cssVariables.colorsBlue
    case "yellow":
      return cssVariables.colorsYellow
    case "contact":
      return cssVariables.colorsGray
    case "frontpage":
    default:
      return cssVariables.colorsDefault
  }
}
